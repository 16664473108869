/* seven49 vars */
$petrol: #15748d;
$seven-blue: #1381a1;
$brand-color: $seven-blue;

$blue: #0271b2;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;



// theme colors

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red ;
$light: $gray-100;
$dark: $gray-800;



$light-primary: lighten($primary, 58%);

$code-color: $teal;


$enable-rounded: false;
$font-family-sans-serif: "Helvetica", arial, sans-serif;

// fonts
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.25;
$h3-font-size: $font-size-base * 1.1;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;



$input-border-radius: 0;

$table-bg: $white;
$table-border-color: $gray-300;
$table-accent-bg: $gray-200;

$default-icon-width: 36px;

$icon-path: "//cdn.seven49.net/common/cms/images/tags/";

$breakpoints: (
  x-small: 0,
  small: 576px,
  medium: 768px,
  large: 992px,
  x-large: 1200px
);

/////////////////////////////////////////////////////
/// mobile first
/////////////////////////////////////////////////////
@mixin media($width) {
   @if map-has-key($breakpoints, $width) {
    @media (min-width: map-get($breakpoints, $width)) {
      @content;
    }
  } @else {
    @media (min-width: $width) {
      @content;
    }
  }
}

@mixin media-down($width) {
   @if map-has-key($breakpoints, $width) {
    @media (max-width: map-get($breakpoints, $width)) {
      @content;
    }
  } @else {
    @media (max-width: $width) {
      @content;
    }
  }
}

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function url-encode($string) {
  $map: ("%": "%25",
      "<": "%3C",
      ">": "%3E",
      " ": "%20",
      "!": "%21",
      "*": "%2A",
      "'": "%27",
      '"': "%22",
      "(": "%28",
      ")": "%29",
      ";": "%3B",
      ":": "%3A",
      "@": "%40",
      "&": "%26",
      "=": "%3D",
      "+": "%2B",
      "$": "%24",
      ",": "%2C",
      "/": "%2F",
      "?": "%3F",
      "#": "%23",
      "[": "%5B",
      "]": "%5D"
    );

  $new: $string;

  @each $search,
  $replace in $map {
    $new: str-replace($new, $search, $replace);
  }

  @return $new;
}

// Format the SVG as a URL
@function inline-svg($string) {
  @return url('data:image/svg+xml,#{url-encode($string)}');
}