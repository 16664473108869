@mixin input-text() {
  margin: 0;
  color: $input-color;
  line-height: $input-line-height;
  border: $input-border-width solid $input-border-color;
  background: $input-bg;
  box-shadow: $input-box-shadow;
  padding: $input-padding-y $input-padding-x;
  border-radius: $input-border-radius;
}

@mixin select() {
  max-width: 100%;
  border: 1px solid $input-border-color;
  width: auto;
  font-size: 1rem;
  font-family: inherit;
  line-height: normal;
  color: inherit;
  background-color: $input-bg;
  border-radius: $input-border-radius;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-size: 9px 6px;
  background-position: right -.825rem center;
  padding-top: .375rem;
  padding-right: 1.5rem;
  padding-bottom: .375rem;
  padding-left: .75rem;
  background-origin: content-box;
  background-repeat: no-repeat;
}

@mixin form-group() {
  margin-bottom: $form-group-margin-bottom;
}

@mixin form-text() {
  display: block;
  margin-top: $form-text-margin-top;
}

@mixin form-control() {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @if $enable-rounded {
    // Manually use the if/else instead of the mixin to account for iOS override
    border-radius: $input-border-radius;
  } @else {
    // Otherwise undo the iOS default
    border-radius: 0;
  }

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

/* tables */

@mixin table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacer;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }

  // .table {
  //   background-color: $body-bg;
  // }
}


//
// Condensed table w/ half padding
//

@mixin table-sm {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}


// Bordered version
//
// Add borders all around the table and between all the columns.

@mixin table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }

  thead {
    th,
    td {
      border-bottom-width: (2 * $table-border-width);
    }
  }
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

@mixin table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: $table-accent-bg;
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

@mixin table-hover {
  tbody tr {
    @include hover {
      background-color: $table-hover-bg;
    }
  }
}
