$error-color:red !default;
$warning-color:orange !default;
$success-color:green !default;

// password icons
$password-icon-0: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path style="fill:#{$error-color}" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zM136 208c0-17.7 14.3-32 32-32s32 14.3 32 32-14.3 32-32 32-32-14.3-32-32zm187.3 183.3c-31.2-9.6-59.4-15.3-75.3-15.3s-44.1 5.7-75.3 15.3c-11.5 3.5-22.5-6.3-20.5-18.1 7-40 60.1-61.2 95.8-61.2s88.8 21.3 95.8 61.2c2 11.9-9.1 21.6-20.5 18.1zM328 240c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"/></svg>') !default;

$password-icon-1: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path style="fill:#{$error-color}" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-80 128c-40.2 0-78 17.7-103.8 48.6-8.5 10.2-7.1 25.3 3.1 33.8 10.2 8.4 25.3 7.1 33.8-3.1 16.6-19.9 41-31.4 66.9-31.4s50.3 11.4 66.9 31.4c8.1 9.7 23.1 11.9 33.8 3.1 10.2-8.5 11.5-23.6 3.1-33.8C326 321.7 288.2 304 248 304z"/></svg>') !default;

$password-icon-2: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path style="fill:#{$error-color}" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm8 144H160c-13.2 0-24 10.8-24 24s10.8 24 24 24h176c13.2 0 24-10.8 24-24s-10.8-24-24-24z"/></svg>') !default;

$password-icon-3: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path style="fill:#{$warning-color}" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm8 144H160c-13.2 0-24 10.8-24 24s10.8 24 24 24h176c13.2 0 24-10.8 24-24s-10.8-24-24-24z"/></svg>') !default;

$password-icon-4: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path style="fill:#{$success-color}" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z"/></svg>') !default;


.pwd-check-strength {

  display: none;

  font-size: .825rem;
  margin: 5px 0;

  span {
    line-height: 25px;
    display: inline-block;
    vertical-align: middle;
  }

  &:before {
    content: "";
    display: inline-block;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: $password-icon-0;
    height: 25px;
    width: 25px;
    vertical-align: middle;
    margin-right: 5px;
  }

  &.pwd-check-score-0 {
    color: $error-color;
  }

  &.pwd-check-score-1 {
    color: $error-color;

    &:before {
      background-image: $password-icon-1;
    }
  }

  &.pwd-check-score-2 {
    color: $error-color;

    &:before {
      background-image: $password-icon-2;
    }
  }

  &.pwd-check-score-3 {
    color: $warning-color;

    &:before {
      background-image: $password-icon-3;
    }
  }

  &.pwd-check-score-4 {
    color: $success-color;

    &:before {
      background-image: $password-icon-4;
    }
  }
}