.MainNavigation,
.main-navigation {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: .9rem;
  }
  >ul {
    display: flex;

    >li {
      position: relative;
      margin-right: 4px;
      color: $white;
      z-index: 5;
      >a {
        background: darken($blue, 8%);
        display: block;
        padding: 0 .75rem;
        line-height: 2.2rem;
        color: $white;
        border: 1px solid darken($blue, 8%);
        border-bottom-width: 0;
      }
      &:hover {
        ul {
          display: block;
          width: 275px;
          left: 0;
          opacity: 1;
          padding: 5px 0;
        }
      }
    }
    ul {
      position: absolute;
      top: 2.19rem;
      left: -2000000px;
      background: darken($blue, 8%);
      opacity: 0;
      transition: opacity 300ms ease;
      z-index: 200;
      li {
        display: block;
        a {
          line-height: 1.8rem;
          padding: .25rem .75rem .25rem 2.5rem;
          display: block;
        }
      }
    }
  }
  .selected>a {
    background: darken($blue, 5%);
    box-shadow: 0 0 2px rgba($white, .31)
  }
  .selected svg.svg-inline--fa {
    color: $white;
  }
}

//icons
.main-navigation {
  li li {
    a {
      position: relative;
      display: block;
      i,
      svg.svg-inline--fa {
        position: absolute;
        line-height: 1.8rem;
        font-size: 1.2rem;
        width: 1.5rem;
        top: .475rem;
        left: .45rem;
        z-index: 3;
      }
    }
  }

  // dev
  li li {
    position: relative;

    i,
    svg.svg-inline--fa {
      position: absolute;
      line-height: 1.8rem;
      font-size: 1.2rem;
      width: 1.5rem;
      top: .475rem;
      left: .45rem;
      z-index: 3;
    }
  }
}