textarea,
input[type=text],
input[type=email] {
  @include form-control();
}


textarea.form-control,
.ContentTextEditText textarea,
textarea {
  resize: both;
}

legend {
  font-size: $h3-font-size;
}

select,
select.form-control,
.header select {
  @include select();
}

.panel-search,
.PanelSearch {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  label {
    display: none;
  }
  .k-widget {
    border: 0 none;
    margin: 0;
    padding: 0;
    background: transparent;
    width: 100%;
    label {
      display: none;
    }
    input[type=text] {
      @include input-text();
      text-indent: 0;
      box-sizing: border-box;
    }
    &.k-state-focused {
      border-color: transparent;
      input[type=text] {
        box-shadow: 0 0 0 transparent;
        border-color: $primary;
      }
    }
  }

  div.search,
  div.Search {
    // max-width: calc(100% - 100px);
    width: calc(100% - 100px); // @include media-breakpoint-up(md) {
    //  width: auto;
    //  max-width: calc(100% - 100px);
    // }
    margin-right: .5rem;
  }
}

.login-and-password-recovery,
.LoginAndPasswordRecovery {
  max-width: 568px;
  margin: 0 auto;

  .login-title,
  .Login-Title {
    display: none;
  }
  label {
    font-size: .9rem;
  }
  label em {
    font-style: normal;
  }
  span[style*=hidden] {
    display: none;
  }
  .login-user,
  .login-password,
  .login-rememberme,
  .login-submit,
  .email-address,
  .Login-User,
  .Login-Password,
  .Login-RememberMe,
  .Login-Submit,
  .EmailAddress   {
    @include form-group();
  }
  .login-user,
  .login-password,
  .Login-User,
  .Login-Password  {
    input {
      @include form-control();
    }
  }
}

.radio-button,
.CheckBoxWithFormLabel,
.form-check {
  label {
    margin-left: .75rem;
  }
}

.status .radio-button {
  margin-right: $grid-gutter-width/2;
}

/* textareas */

.ContentTextEditText {
  textarea {
    min-height: 400px;
    width:675px;
    max-width: 100%;
    font-size: .825rem;
  }
}
// legacy ?
.TextBoxFullSize {
  width: 100%;
  // height: 600px;
}
.download,
.link {
  textarea {
    max-width: 675px;
    height: 200px;
    font-size: .825rem;
  }
}

.form-check {
  span.form-check-input {
    input[type=checkbox] {
      display: inline-block;
      vertical-align: top;
    }
  }
  label.CheckBoxLabel {
    margin-left: .25rem;
  }
}


.form-check > input[type=checkbox] {
  position: absolute;
  margin-top: $form-check-input-margin-y;
  margin-left: -$form-check-input-gutter;

  &:disabled ~ .form-check-label {
    color: $text-muted;
  }
}

.form-check-label {
  margin-bottom: 0; // Override default `<label>` bottom margin
}

/* input text: image, link */
.heading,
.download,
.link,
.image {
  input[type=text] {
    max-width: 675px;
  }
}

.contentproperties {
  .tab-pane {
    text-area,
    input[type=text] {
     width:675px;
      max-width: 100%;
    }
  }
}

.validator[style*="visibility:hidden"] {
  display: none;
}