.page-main-content {
  table.rgMasterTable ,
  table {
    font-family: $font-family-sans-serif;
    font-size: .825rem;
    line-height: 1.1;
    @include table();
    @include table-sm();
    @include table-bordered();
    // @include table-striped();

    tr.rgAltRow {
      background-color: $table-accent-bg;
    }
    th.rgHeader {
      background: $table-accent-bg none;
      color: $black;
      padding-top: .75rem;
      padding-bottom: .75rem;
    }
  }

  div.RadGrid_Default  {
    border-color: $white;
  }

  .RadEditor table{
    background-color: #eee;
  }

}

// /////////////////////////////////
// // tags bootstrap
// /////////////////////////////////

// .page-main-content {
//   #tags {
//     .form-check label {
//         font-size: $small-font-size;
//       }
//   }
//   .bootstrap-tags {
//     list-style: none;
//     margin: 0;
//     padding:0;

//     li .form-check {
//       display: inline-block;
//       width: 100px;
//       vertical-align: middle;
//       label {

//       }

//     }

//     li .table-container {
//       margin-top: .7rem;
//       display: inline-block;
//       width: calc(100% - 105px);
//       vertical-align: middle;
//     }
//   }
//   table.bootstrap-layout {
//     background: $white;
//     // border-spacing: 2px;
//     // border-collapse: separate;
//     border-collapse: collapse;
//     border: 0 none;

//     td {
//       background: $primary;
//       border: 0 none;
//     }
//     &[class*="-12"] {
//       width: 100%;
//     }
//     &[class*="-3"] {
//       width: percentage(3/12);
//     }
//     &[class*="-4"] {
//       width: percentage(4/12);
//     }
//     &[class*="-5"] {
//       width: percentage(5/12);
//     }
//     &[class*="-6"] {
//       width: percentage(6/12);
//     }
//     &[class*="-7"] {
//       width: percentage(7/12);
//     }
//     &[class*="-8"] {
//       width: percentage(8/12);
//     }
//     &[class*="-9"] {
//       width: percentage(9/12);
//     }
//     &[class*="-10"] {
//       width: percentage(10/12);
//     }
//     &[class*="-11"] {
//       width: percentage(11/12);
//     }
//   }

// }

