.header {
  background-color: $primary;
  padding:$spacer/2 $grid-gutter-width/2;

  position: relative;
  z-index: 1;
  height: 100px;
  display: flex;
  flex-flow: row nowrap;

  @include media-breakpoint-up(md) {
    min-width: map-get($grid-breakpoints, "md");
  }
}
.header a {
  color: #fff;
  text-decoration: none;
}

.header {
  .project-selection,
  .ProjectSelection {
    display: flex;
    div.drop-down-list,
    div.DropDownList {
      display: inline-block;
      max-width: 250px;
      height: 30px;
      select {
        width: 100%;
        max-width: 100%;
      }
      label {
        display: none;
      }
    }
  }
}

.header div.main-navigation {
  position: absolute;
  bottom: 0;
  z-index: 100;
  left: $spacer;
}

.header a.home,
.header a.home:focus,
.header a.Home,
.header a.Home:focus {
  background: url(../images/seven49-logo-white.svg);
  background-size: 100% auto;
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 191px;
  height: 31px;
  float: right;
  display: block;
  text-indent: -9999px;
  position: absolute;
  right: 20px;
  top: 12px;
  outline: none;
}

.header {
  .project-tools,
  .ProjectTools {
    float: left;
    margin: 0;
    padding: 0 0 0 15px;
    ul {
      list-style: none;
      margin: 0;
      padding:0;
      li {
        float: left;
      }
    }
  }

  .project-tools li.preview.url,
  .ProjectTools li.Preview.Url {
    font-size: 1.2rem;
    float: left;
    margin: 0 15px 0 0;
    svg {
      display: inline-block;

    }
  }

  .project-tools li.project-info a,
  .ProjectTools li.ProjectInfo a {
    margin: 0;
    // line-height: 25px;
    float: left;
    font-weight: normal;
    font-size: 1.2rem;
  }
}

.header {
  .meta-navigation,
  .MetaNavigation {
    display: flex;
    align-self: flex-end;
    position: absolute;
    right: $grid-gutter-width/2;
    bottom: 0;
    margin-bottom: 0;

    list-style: none;
    li {
      padding: 5px 5px 5px 15px;
      display: inline-flex;
      // font-size: 1.2rem

    }
  }
}

.header {
  .svg-inline--fa {
    font-size: 1.6rem;

  }
}


.page-head {
  background: $light-primary;
  padding: $spacer/2 $spacer;
  border-bottom: 1px solid lighten($primary, 3%);
  h1 {
    line-height: 1;
    padding: 0;
    margin: 0;
    font-size: .9rem;
    color: $primary;
  }
}

//div#PageHead h1 {
//   font-size: 0.9em;
//   margin: 0;
//   line-height: 25px;
//   padding: 0 12px;
//   color: #2D5376;
// }