/*

inactive immer grau
active grün oder gelb
- unpublished gelb
- published grün

*/

li.rtLI {
  svg {
    font-size: 1rem;
    margin-right: 4px;
  }
  .published svg {
    color: $green;
  }
  .active svg {
    color: $green;
  }
  .active.unpublished svg {
    color: $yellow;
  }
  .inactive svg {
    color: $gray-500 !important;
  }
}

table[class*=rgMaster],
table.grid-view,
table.RadGrid {
  td {
    img[src*="/icons/"] {
      display: inline-block;
      width: $default-icon-width;
    }

    input[type=image] {
      width: $default-icon-width;
    }
  }
}

table.grid-view {
  td {
    a {
      display: inline-block;
      img {
        display: block;
        width: $default-icon-width;
      }
    }
    input[type=image] {
      display: inline-block;
      width: $default-icon-width;
    }
  }
}






table[class*=rgMaster] {

  td:first-child {
    a  {
      display: inline-block;
      vertical-align: middle;

      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }


  td:nth-child(2),
  td:nth-child(3) {
    a > img {
      width: $default-icon-width !important;
      display: inline-block;
      margin-right: 5px;
    }
  }

  input[type=image] {
    width: $default-icon-width;
  }
}