.page-menu,
.PageMenu {
  // outline: 3px solid green;
  background: $light-primary;
  margin: 0 -#{$grid-gutter-width/2}; // @include make-row();


  ul {
    list-style: none;
    padding: 5px $grid-gutter-width;

    display: flex;
    flex-direction: row nowrap;
  }
  li {
    list-style: none;
    display: inline-flex;
    margin: 0 5px 0 0;
    &.preview {
    	margin-left: $grid-gutter-width;
    }

    a {
      display: block;
      text-align: center;
      border: 1px solid $white;
      padding: 5px;
      &:hover {
        text-decoration: none;
        color: $black;
        border-color: $black;
      }
      .svg-inline--fa {
        font-size: 2rem;
      }
      span {
        display: block;
        font-size: .75rem;
        font-weight: normal;

      }
    }
  }
}

.page-menu,
.PageMenu {
  &.icons-only {
    li a span {
      display: none;
    }
  }
}



