.status {
  margin-top: $grid-gutter-width;
}

.contentproperties {
  .tab-pane > div {
    margin-bottom: $grid-gutter-width/2;
  }
  .nav-tabs {
    margin-top: $grid-gutter-width;
  }

  .related-content {
    margin-top: $spacer*3;

    legend {
      font-size: $h2-font-size;
    }
  }
}