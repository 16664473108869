#DocumentPreview {
  &[style*="visibility: hidden"] {
    display: none;
  }
}

.Trail {
  font-size: .825rem;
  ul {
    list-style: none;
    padding: .5rem 0 $spacer 0;
    margin: 0;

    li {

      display: inline-block;

      &::after {
        content: "/";
        display: inline-block;
        font-size: inherit;
        margin-left: .45rem;
        margin-right: .25rem;
        padding-right: .25rem;
      }
    }
    // li:first-child:after {
    //   content: "";
    //   margin-right: 0;
    //   margin-left: 0;
    //   padding-left: 0;
    // }
  }
}