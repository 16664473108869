/* overwrites Telerik Menu */

 .dock-layout {
  .RadDock .rdCenter .rdCommands a,
  .RadDock .rdCenter .rdCommands a span {
    text-indent: 0;
    width: 24px;
    height:24px;
    line-height: 1;
    cursor: pointer;
    background-image: none;
    svg {
      font-size: 1.25rem;
    }
  }
  .RadDock .rdCenter .rdCommands {
    li {
      margin-left: $spacer/2;
    }
  }
}


.RadTreeView {
  .rtLines {
    .rtLI {
      background-image: none;
      .rtSelected,
      .rtSelected.rtHover,
      .rtHover {
        .rtIn {
          background-image: none;
          background-color: $gray-100;
          border-color: $gray-300;
        }
      }
      .rtSelected,
      .rtSelected.rtHover {
        .rtIn {

          color: $primary;
        }
      }
    }
  }
}
.RadMenu {
  .rmGroup.rmVertical {
    background-image: none;
    background-color: $white;
    border-color: $gray-300;
    .rmItem * {
      background-image: none;
    }
    .rmLink {
      &:hover {
        background-color: $gray-200;
      }
    }

  }
}

.dock-layout {
  .RadDockZone {
    background-image: none;
    border: 1px solid $gray-300;
    padding: 10px 10px 1px 10px;
  }
  .RadDock_Default {
    margin-bottom: 10px;
    box-shadow: 2px 2px 3px rgba($black, .1);

    .rdTable {
      border:1px solid  $gray-300;
      tr,
      td {
        border-color: $gray-300;
      }
    }
    .rdTop {
      .rdLeft,
      .rdCenter,
      .rdRight {
        background-image: none;
        background-color: $gray-200;
      }
    }
    .rdMiddle,
    .rdBottom {
      .rdLeft,
      .rdCenter,
      .rdRight {
        background-image: none;
      }
    }
  }
}

.page-main-content {
  .RadPicker_Default .rcCalPopup {
    z-index: 0;
  }
}

/* Telerik grid component */

.page-main-content {
  div.RadGrid {
    @include media-breakpoint-down(md) {

        overflow: auto;
        overflow-y: hidden;
        overflow-x: auto;
        display: block;
        width: 100%;

    }
  }
}

.page-main-content {
  .RadEditor.Default.reWrapper table {
    margin-bottom: auto;
  }
}
/* table manager */

.tablemanager {
  .page-main-content {
    padding-top: $grid-gutter-width/2;

    select {
      @include select();
      padding-top: .5rem;
      padding-bottom: .5rem;
      padding-left: .5rem;
    }
  }
}

.page-main-content {
  .Default.reWrapper {
    border-color: $white;
  }
}

.page-main-content {
  #grid {
    margin: $grid-gutter-width/2 0;
  }
}
.image {
  .wysiwyg {

    table {

    }
  }
}
// bootstrap overwrites

.rdTable {
  [class*="col-"] {
    width: 100%;
    max-width: 100%;
    display: block;
    padding-right: 0;
    padding-left: 0;
  }
  .mx-md-auto {
    margin-right: 0;
    margin-left: 0;
  }

  .Object {
    padding-top: $grid-gutter-width/4;
  }
}

//
.LoginAndPasswordRecovery {
  margin: .75rem 0;
  .PasswordRecovery.password-recovery {
    display: block !important;
  }
}
.resources-grid-view-filter {
  .Filter {
    display: inline-block;
    margin-right: .5rem;
  }
}
.resources-grid-view-search {
  margin: .75rem 0;

  input[type=text] {
    display: inline-block;
    width: calc(100% - 110px);
    vertical-align: middle;
  }
  span {
    display: inline-block;
    width: $default-icon-width;
    padding-top: .25rem;
    margin: .25rem 0 0 5px ;
    vertical-align: middle;
    input[type=image] {
      // margin-top: .25rem;
      display: inline-block;
      width: $default-icon-width;
    }
  }
  span.Search {
    display: block;
    padding: 0;
    margin: 0;
  }
}