
.panel-tree,
.PanelTree {

  img.rtImg,
  .rtImg  {
    width: 20px;
    max-width: 20px;
    height: auto;
  }
}

.rmLeftImage {
  width: 20px;
  height: auto;
}