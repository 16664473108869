.empty {
  display: none;
}

.hidden {
  display: none;
}

.page-title {
  h1 {
    margin: $grid-gutter-width/2 0;
  }
}

table {
  width: 100%;
  clear: both;
  margin-bottom: 0px;
}

table.RadEToolbar {
  width: auto;
  clear: none;
}

.alert {
  display: inline-block;
  padding: 2px 4px;
  vertical-align: middle;
  background-color: theme-color("warning");
  color: theme-color("danger");
  font-size: .75rem;

  .browse & {
    position: relative;
    margin-bottom: 0;
    margin-left: 6px;
    padding: 0 4px 0 8px;
    line-height: 1.25rem;
    font-size: .75rem;
    &:before {
      content: " ";
      position: absolute;
      height: 12px;
      width: 12px;
      background-color: theme-color("warning");
      margin: -6px 0;
      top: 50%;
      left: -5px;
      transform: rotate(-45deg);

    }
  }
}

.hint {
  background-color: theme-color("warning");
  display: inline-block;
  padding: 0 4px;
  color: $white;
  font-size: .75rem;
}


/*html validierung mit css*/
span.Issue {
  font-size: 120%;
  color: black;
  font-weight: bolder;
}
span.QualidatorIssue {
  background-color: #fffe89;
}
span.MarkedHtml {
  background-color: #ff787b;
}
span.LineNumber {
  margin: 2px 12px 2px 0;
  background-color: #eee;
  padding: 2px 5px;
}

span.Tag {
  color: #006699;
}
span.Name {
  color: #D6AD80;
}
span.Value {
  color: #3A90FF;
}
span.XhtmlIssue {
  background-color: #ff787b;
}

/* image max-width in editor */
.dock-layout {
  table img {
    max-width: 100%;
    height: auto;
  }
}