.dock-layout {
  ul.tags {
    list-style: none;
    margin: 0 0 0 .5rem;
    padding: 0;
    display: inline-block;

    li {
      display: inline-block;
      margin: 0 .5rem 0 0;
      padding: 3px;

      &:last-child {
        margin: 0;
      }

      &[class*=col-] {
        width: auto;
        max-width: 100%;
      }

      &[class*=tag-col-]:before {
        width: 36px;
        height: 36px;
      }

      &[class*=tag-col-] span {
        //display: none;
      }

      &:before {
        display: inline-block;
        content: "";
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 90% auto;
        vertical-align: middle;
      }

      &.tag-col-12 {
        margin-right: 0;
        span {
          @include sr-only();
        }
        &:before {
          background-image: url("#{$icon-path}mobile_full.svg?2018-10-22");
          background-size: auto 90%;
        }
      }

      &.tag-col-sm-6 {
        span {
          @include sr-only();
        }
        &:before {
          background-image: url("#{$icon-path}mobile_landscape.svg");
          // background-size: auto 90%;
        }
      }

      &.tag-col-lg-3 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}desktop_col_3.svg");
        }
      }

      &.tag-col-lg-4 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}desktop_col_4.svg");
        }
      }

      &.tag-col-lg-5 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}desktop_col_5.svg");
        }
      }

      &.tag-col-lg-6 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}desktop_col_6.svg");
        }
      }

      &.tag-col-lg-7 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}desktop_col_7.svg");
        }
      }

      &.tag-col-lg-8 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}desktop_col_8_mx.svg");
        }
      }
      &.tag-col-lg-9 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}desktop_col_9.svg");
        }
      }

      &.tag-col-lg-12 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}desktop_col_12.svg");
        }
      }


      &.tag-col-md-3 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}laptop_col_3.svg");
        }
      }

      &.tag-col-md-4 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}laptop_col_4.svg");
        }
      }

      &.tag-col-md-5 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}laptop_col_5.svg");
        }
      }

      &.tag-col-md-6 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}laptop_col_6.svg");
        }
      }
      &.tag-col-md-7 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}laptop_col_7.svg");
        }
      }

      &.tag-col-md-8 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}laptop_col_8_mx.svg");
        }
      }
      &.tag-col-md-9 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}laptop_col_9.svg");
        }
      }

      &.tag-col-md-12 {
        span {
          @include sr-only();
        }

        &:before {
          background-image: url("#{$icon-path}laptop_col_12.svg");
        }
      }

    }
  }
}


/////////////////////////////////
// tags bootstrap
/////////////////////////////////

.page-main-content {
  #tags {
    .form-check label {
        font-size: $small-font-size;
      }
  }
  .bootstrap-tags {
    list-style: none;
    margin: 0;
    //padding: 0 $grid-gutter-width/2;

    li .form-check {
      display: inline-block;
      width: 100px;
      vertical-align: middle;
      label {

      }

    }

    li[class*=tag-col-] {
      .form-check {
        width: auto;
      }
      label:before {
        content: "";
        display: inline-block;
        width: 36px;
        height: 36px;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 90% auto;
        vertical-align: middle;
        margin-right: .75rem;
      }
    }

    li {
      &.tag-col-12 {

        label:before {
          background-image: url("#{$icon-path}mobile_full.svg?2018-10-22");
          background-size: auto 90%;
        }
      }

      &.tag-col-sm-6 {

        label:before {
          background-image: url("#{$icon-path}mobile_landscape.svg");

        }
      }

      &.tag-col-lg-3 {

        label:before {
          background-image: url("#{$icon-path}desktop_col_3.svg");
        }
      }

      &.tag-col-lg-4 {

        label:before {
          background-image: url("#{$icon-path}desktop_col_4.svg");
        }
      }

      &.tag-col-lg-5 {
        label:before {
          background-image: url("#{$icon-path}desktop_col_5.svg");
        }
      }

      &.tag-col-lg-6 {
        label:before {
          background-image: url("#{$icon-path}desktop_col_6.svg");
        }
      }

      &.tag-col-lg-7 {
        label:before {
          background-image: url("#{$icon-path}desktop_col_7.svg");
        }
      }

      &.tag-col-lg-8 {
        label:before {
          background-image: url("#{$icon-path}desktop_col_8_mx.svg");
        }
      }

      &.tag-col-lg-9 {
        label:before {
          background-image: url("#{$icon-path}desktop_col_9.svg");
        }
      }

      &.tag-col-lg-12 {
        label:before {
          background-image: url("#{$icon-path}desktop_col_12.svg");
        }
      }


      &.tag-col-md-3 {
        label:before {
          background-image: url("#{$icon-path}laptop_col_3.svg");
        }
      }

      &.tag-col-md-4 {
        label:before {
          background-image: url("#{$icon-path}laptop_col_4.svg");
        }
      }

      &.tag-col-md-5 {
        label:before {
          background-image: url("#{$icon-path}laptop_col_5.svg");
        }
      }

      &.tag-col-md-6 {
        label:before {
          background-image: url("#{$icon-path}laptop_col_6.svg");
        }
      }
      &.tag-col-md-7 {
        label:before {
          background-image: url("#{$icon-path}laptop_col_7.svg");
        }
      }

      &.tag-col-md-8 {
        label:before {
          background-image: url("#{$icon-path}laptop_col_8_mx.svg");
        }
      }
      &.tag-col-md-9 {
        label:before {
          background-image: url("#{$icon-path}laptop_col_9.svg");
        }
      }

      &.tag-col-md-12 {
        label:before {
          background-image: url("#{$icon-path}laptop_col_12.svg");
        }
      }
    }

    // not in use anymore

    li .table-container {
      margin-top: .7rem;
      display: inline-block;
      width: calc(100% - 105px);
      vertical-align: middle;
    }
  }
  table.bootstrap-layout {
    background: $white;
    // border-spacing: 2px;
    // border-collapse: separate;
    border-collapse: collapse;
    border: 0 none;

    td {
      background: $primary;
      border: 0 none;
    }
    &[class*="-12"] {
      width: 100%;
    }
    &[class*="-3"] {
      width: percentage(3/12);
    }
    &[class*="-4"] {
      width: percentage(4/12);
    }
    &[class*="-5"] {
      width: percentage(5/12);
    }
    &[class*="-6"] {
      width: percentage(6/12);
    }
    &[class*="-7"] {
      width: percentage(7/12);
    }
    &[class*="-8"] {
      width: percentage(8/12);
    }
    &[class*="-9"] {
      width: percentage(9/12);
    }
    &[class*="-10"] {
      width: percentage(10/12);
    }
    &[class*="-11"] {
      width: percentage(11/12);
    }
  }

}

