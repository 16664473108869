.instructions,
.Instructions {
  color: theme-color("info");
  border: 1px solid theme-color("info");
  padding: $spacer/2;

  .link &,
  .historybycontent &,
  .image &,
  /* Login */
  .login-and-password-recovery &,
  .LoginAndPasswordRecovery & {
    margin: $spacer 0;
  }
}

//new
#system-messages,
.system-messages,
.system-messages ul li,
//old
#SystemMessages,
.SystemMessages,
.SystemMessages ul li {
  font-weight: 500;
  color: theme-color("success");
}

div.system-messages,
div.SystemMessages {
  margin: $spacer/2 0;
}

ul.system-messages,
ul.SystemMessages {
  padding: $spacer/2;
  margin: 0;
  list-style: none;
  border: 1px solid theme-color("success");
  font-size: .825rem;
}

.confirmation-panel {
  width: 60vw;
  height: 60vh;
  padding: calc(30vh - 55px) $grid-gutter-width 0 $grid-gutter-width;
  text-align: center;
  background-color: rgba($body-bg, 0.8);
  vertical-align: middle;
  border: 1px solid $primary;
  position: fixed;
  top: 20vh;
  left: 20vw;
  z-index: 20000;
  input[type=button] {
    margin: 0 0 $spacer 0;
    vertical-align: middle;
  }

}

.fa-file-alt.active {
  color: $success;
}

.fa-file-alt.inactive {
  color: $warning;
}