.page-main-content {
  padding: 0;
  border-bottom: 6px solid $primary;
}

.directupload {
  .page-main-content {
    border-bottom: 0 none transparent;
  }
}

#ModernUpload,
#ClassicUpload {
  padding: $grid-gutter-width $grid-gutter-width/2 !important;
  border-bottom: 6px solid $primary;
}

.page-main-content {

  padding: 0 $grid-gutter-width/2;

  .splitter-container,
  .SplitterContainer {
    @include make-row();
  }

  .panel-left,
  .PanelLeft {
    width: 100%;
    @include make-col-ready();
    background: $gray-200;
    padding: $grid-gutter-width/2;
    overflow-x: hidden;

    @include media-breakpoint-up(md) {
      @include make-col(3);
    }

    .PanelTree,
    .panel-tree {
      overflow-x: scroll;
    }
  }

  .splitter-container>.RadAjaxPanel,
  .SplitterContainer>.RadAjaxPanel {
    width: 100%;

    @include make-col-ready();
    min-height: 70vh;

    @include media-breakpoint-up(md) {
      @include make-col(9);
    }
  }

}

.contentsoverview {
  .panel-edit-content {
    //padding-top: $grid-gutter-width/2;
    padding-left: $grid-gutter-width/2;
  }

  .page-menu {
    margin-left: - $grid-gutter-width;
  }

  .node-click-info {
    padding: $spacer;
    min-height: 320px;
  }

  .splitter-container>.RadAjaxPanel {
    padding-top: $grid-gutter-width/2;

    .page-menu {
      margin-top: - $grid-gutter-width/2;
    }
  }
}


.panel-left,
.PanelLeft {
  ul {
    list-style: none;
  }
}


.login-and-password-recovery,
.LoginAndPasswordRecovery {
  padding: $grid-gutter-width 0;
}

// tab component

.tab-content {
  padding-top: $spacer;

}

.tab-pane {
  padding: $spacer 0;
}

.contenttext {
  #ContentTextRank:not(:first-child) {
    padding-top: $spacer*3;
  }
}

/*edit Image*/

#content-text-created-and-changed,
#ContentTextCreatedAndChanged {
  @include form-group;

  span {
    display: block;
    font-size: .825rem;
  }
}


#content-image-path-and-image,
#ContentImagePathAndImage {
  @include form-group;

  a {}

  img {
    max-width: 100%;
    height: auto;
  }
}



// download option

// .tab-pane#basic {
//   @include media-breakpoint-up(md) {
//     @include make-row;
//   }
// }

// {
//   @include media-breakpoint-up(md) {
//     @include make-col-ready();
//     @include make-col(6);
//   }
// }

// .content-image-path-and-image {
//   @include media-breakpoint-up(md) {
//     @include make-col-ready();
//     @include make-col(12);
//   }
// }

.download {
  a.path {

    margin: $spacer 0;
  }
}

[id*=_LinkButtonShowDownloads] {
  display: block;
  margin: $spacer 0;
}

.image {

  .wysiwyg,
  .Wysiwyg {
    padding: 0;
  }

  .Signature {
    padding-top: $spacer*3;
  }
}

/* panel generate */
.panel.generate,
.Panel.Generate {

  padding: $spacer 0;
  min-height: 200px;

  .system-messages,
  .SystemMessages {
    margin: $spacer 0 0 0;
  }
}


#ContentTextCreatedAndModified {
  margin-bottom: $spacer;

  span {
    display: block;
    font-size: .825rem;
  }
}

/*edit Text*/

#ContentTextCreatedAndModified {
  //padding: $spacer 0;

}

.content_contenttext .reWrapper {
  // margin: 6px 12px;
}


#ContentTextSwitch {
  margin-top: $spacer;
  margin-bottom: $spacer;

  span {
    a svg {
      margin-right: $spacer/2;
      font-size: 1.25rem;
    }
  }
}

#ContentTextSwitch input,
#ContentTextSave input {
  // position: relative;
  // top: 6px;
}

#ContentTextSwitch a,
#ContentTextSave a {
  // margin-left: 4px;
}

/* image */
#ContentImagePathAndImage img {
  display: block;
  // border: 1px solid #c8c8c8;
  // border-right-width: 2px !important;
  // border-bottom-width: 2px !important;
  // padding: 0;
  // margin: 0 0 5px 0;
}

#ContentImagePathAndImage a {
  display: block;
}

#ContentImage .Instructions {
  display: block;
  margin: $spacer 0;
  // padding-top: 0px;
  // margin-bottom: 0px;
  // padding-bottom: 18px;
}

// #ContentImage select {
//   width: auto;
// }

// #ContentImage input[type=text],
// #ContentImage textarea {
//   width: 500px;
// }

// #ContentImage ul li input {
//   width: auto;
// }

// #ContentImage div.Wysiwyg {
//   margin-bottom: 18px;
// }

/* code */


code {
  font-family: courier, monospace;
}


/* tags */
.rdTitleBar .tags {
  display: inline-block;
  margin: 0 0 0 $spacer/2;
  font-family: courier, monospace;
}


.tab-pane {
  .row {

    // margin-right: 0;
    // margin-left: 0;
    .Tags {
      @include make-col-ready;
      @include make-col(12);
    }

    .row-tags,
    .bootstrap-tags,
    .default-tags {
      margin-top: $grid-gutter-width/2;
      margin-bottom: $grid-gutter-width/2;

      .form-check {
        padding-left: 0;
      }
    }
  }
}

.crmmailhistoryoverview,
.crmmailqueue,
.crmmailqueueedit,
.crmmailpassratestats,
.crmmailhistoryviewmessage,
.crmmailclickstats,
.crmsettings,
.crmmailsendmessage,
.crmmailtemplates,
.crmcontacts,
.crmmailnewslettermanagement,
.groupswebsite,
.stats,
.transactions,
.timetracking,
.groupswebsiteuserrelations {
  .SplitterContainer {

    >.PanelEditContent {
      @include make-col-ready();

      @include media-breakpoint-up(md) {
        @include make-col(9);
      }
    }
  }
}

.skins {
  .SplitterContainer {}
}

// large images in overview
.contentsoverview {
  .rdTable {
    .ImageContainer img {
      @include media-breakpoint-up(md) {
        max-width: 600px;
        height: auto;
      }
    }
  }
}

.nav-tabs {
  margin-top: $grid-gutter-width;
}

.groupswebsiteuserrelations {
  .PanelEditContent {
    .Instructions {
      margin-bottom: $spacer;
    }

  }
  .add-to-group,
    .remove-from-group {
      display: inline-block;
      margin: $spacer*2 $spacer $spacer 0;
    }
    ul+ h3 {
      margin-top: $grid-gutter-width;
    }
}


.crmmailsendmessage {
  .PanelEditContent {
    .Instructions {
      margin-bottom: $spacer;
       max-width: 600px;
    }
    div {
      fieldset {
        max-width: 600px;
        margin: $spacer*2 0;

        &.Body {
          padding-bottom: 40px
        }
      }
      div.button {
         margin: $spacer 0;
      }
    }
  }
}

